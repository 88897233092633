<template>
  <div class="levelOneDiv" style="background-color: #fff;">
    <!-- <div style="padding: 10px 20px 10px; ">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for="item in breadList" :key="item.key">{{ item.value }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <div v-if="!componentName" class="table_box">
      <div class="box1">
        <templateTree ref="templateTree" :treeData="treeData" :dck="dck" @refreshNode="refreshNode" />
      </div>

      <div class="box2">
        <div class="table_right">
          <!-- 搜索筛选 -->
          <el-form
            :inline="true"
            :model="searchObj"
            :rules="rules"
            ref="form"
            style="align-items: center;margin-bottom: 4px;"
            class="user-search flex-between"
            @submit.native.prevent
          >
            <div>
              <el-form-item label="预算年度" prop="year">
                <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
              </el-form-item>

              <el-form-item>
                <el-button size="mini" plain icon="el-icon-search" @click="search"></el-button>
              </el-form-item>

              <el-form-item>
                <el-button size="mini" plain icon="el-icon-refresh-right" @click="reset"></el-button>
              </el-form-item>
            </div>

            <div>
              <el-form-item>
                <el-button size="mini" type="primary" icon="el-icon-plus" @click="handleAdd('add', {})">新建</el-button>
              </el-form-item>
              <el-form-item>
                <el-button size="mini" type="primary" plain icon="el-icon-download" @click="importExcel()"
                  >导入</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button size="mini" type="primary" plain icon="el-icon-upload2" @click="exportExcel()"
                  >导出</el-button
                >
              </el-form-item>

              <!-- <el-form-item>
                <el-button size="mini" type="primary" plain icon="el-icon-s-data" @click="handleView('charts', {})"
                  >查看统计图</el-button
                >
              </el-form-item> -->
            </div>
          </el-form>

          <div style="position: relative;">
            <el-table
              v-loading="loading"
              ref="table"
              :data="listData"
              style="width: 100%;position: absolute;"
              height="740px"
              :span-method="objectSpanMethod"
            >
              <el-table-column fixed prop="county" align="center" width="80" label="行政区域"> </el-table-column>

              <el-table-column fixed prop="year" show-overflow-tooltip align="center" width="160" label="指标">
              </el-table-column>

              <el-table-column align="center" label="后扶移民涉及工程和行政区域情况">
                <el-table-column align="center" label="纳入后扶范围大中型水库">
                  <el-table-column width="170" align="center" prop="hubLocations" label="枢纽工程所在地（座）">
                    <template slot-scope="scope">
                      {{ scope.row.hubLocations }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    width="170"
                    prop="onlyReservoirLocations"
                    label="仅库区所在地（座）"
                  ></el-table-column>
                </el-table-column>

                <el-table-column
                  align="center"
                  width="110"
                  prop="immigrationTowns"
                  label="移民乡（镇）（个）"
                ></el-table-column>
                <el-table-column
                  align="center"
                  width="110"
                  prop="immigrationVillages"
                  label="移民村（个）"
                ></el-table-column>
                <el-table-column
                  align="center"
                  width="110"
                  prop="immigrationGroups"
                  label="移民组（个）"
                ></el-table-column>
              </el-table-column>

              <el-table-column align="center" label="后扶移民基本情况">
                <el-table-column
                  align="center"
                  width="110"
                  prop="postSupportPopulation"
                  label="后期扶持人数（人）"
                ></el-table-column>
                <el-table-column
                  align="center"
                  width="110"
                  prop="postSupportRealizedPopulation"
                  label="后期扶持兑现人数（人）"
                ></el-table-column>
                <el-table-column
                  align="center"
                  width="110"
                  prop="immigrationFarmlandPerPerson"
                  label="移民人均耕地（亩）"
                ></el-table-column>
                <el-table-column
                  align="center"
                  width="110"
                  prop="immigrationHousingAreaPerPerson"
                  label="移民人均住房面积（㎡）"
                ></el-table-column>
                <el-table-column
                  align="center"
                  width="110"
                  prop="immigrationDisposableIncomePerPerson"
                  label="移民人均可支配收入（元）"
                >
                  <template slot-scope="scope">
                    {{ formatNumericWithTwoDecimalPlaces(scope.row.immigrationDisposableIncomePerPerson) }}
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  width="200"
                  prop="aboveCountyAverageSupportPopulation"
                  label="可支配收入在本县农村居民人均以上的后扶人数（人）"
                ></el-table-column>
                <el-table-column
                  align="center"
                  width="110"
                  prop="povertyAlleviationSupportPopulation"
                  label="脱贫移民后扶人口（人）"
                ></el-table-column>

                <el-table-column align="center" label="劳动力人数">
                  <el-table-column
                    width="130"
                    align="center"
                    prop="totalLaborForcePopulation"
                    label="总人数（人）"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    width="200"
                    prop="laborForceOutputPopulation"
                    label="其中劳动力输出人数（人）"
                  ></el-table-column>
                </el-table-column>

                <el-table-column
                  align="center"
                  width="110"
                  prop="cooperativeMedicalParticipants"
                  label="参与新型农村合作医疗（人）"
                ></el-table-column>
                <el-table-column
                  align="center"
                  width="110"
                  prop="ruralPensionInsuranceParticipants"
                  label="参与新型农村养老保险（人）"
                ></el-table-column>
              </el-table-column>

              <el-table-column align="center" label="移民村组基本情况">
                <el-table-column align="center" label="有村卫生室的移民村">
                  <el-table-column
                    align="center"
                    width="130"
                    prop="healthCentersRatioInVillages"
                    label="数量（个）"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    width="150"
                    prop="healthCentersRatioInVillagesRatio"
                    label="占移民村比率（%）"
                  >
                    <template slot-scope="scope">
                      {{
                        scope.row.immigrationDisposableIncomePerPerson
                          ? scope.row.immigrationDisposableIncomePerPerson + '%'
                          : ''
                      }}
                    </template>
                  </el-table-column>
                </el-table-column>

                <el-table-column align="center" label="引水安全的移民村">
                  <el-table-column
                    align="center"
                    width="130"
                    prop="drinkingWaterSafeSupportVillages"
                    label="数量（个）"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    width="150"
                    prop="drinkingWaterSafeSupportVillagesRatio"
                    label="占移民村比率（%）"
                  >
                    <template slot-scope="scope">
                      {{
                        scope.row.drinkingWaterSafeSupportVillagesRatio
                          ? scope.row.drinkingWaterSafeSupportVillagesRatio + '%'
                          : ''
                      }}
                    </template>
                  </el-table-column>
                </el-table-column>

                <el-table-column align="center" label="引水安全的后扶移民人口">
                  <el-table-column
                    align="center"
                    width="130"
                    prop="drinkingWaterSafeSupportPopulation"
                    label="数量（个）"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    width="150"
                    prop="drinkingWaterSafeSupportPopulationRatio"
                    label="占移民村比率（%）"
                  >
                    <template slot-scope="scope">
                      {{
                        scope.row.drinkingWaterSafeSupportPopulationRatio
                          ? scope.row.drinkingWaterSafeSupportPopulationRatio + '%'
                          : ''
                      }}
                    </template>
                  </el-table-column>
                </el-table-column>

                <el-table-column align="center" label="通公路的">
                  <el-table-column align="center" width="130" prop="roadsCount" label="数量（个）"></el-table-column>
                </el-table-column>

                <el-table-column align="center" label="移民村">
                  <el-table-column align="center" width="150" prop="immigrationVillagesRatio" label="占移民比率（%）">
                    <template slot-scope="scope">
                      {{ scope.row.immigrationVillagesRatio ? scope.row.immigrationVillagesRatio + '%' : '' }}
                    </template>
                  </el-table-column>
                </el-table-column>

                <el-table-column align="center" label="通机耕道的">
                  <el-table-column
                    align="center"
                    width="130"
                    prop="machineFarmingRoadsCount"
                    label="数量（个）"
                  ></el-table-column>
                </el-table-column>

                <el-table-column align="center" label="移民组">
                  <el-table-column align="center" width="150" prop="immigrationGroupsRatio" label="占移民组比率（%）">
                    <template slot-scope="scope">
                      {{ scope.row.immigrationGroupsRatio ? scope.row.immigrationGroupsRatio + '%' : '' }}
                    </template>
                  </el-table-column>
                </el-table-column>

                <el-table-column align="center" label="通电的移民组">
                  <el-table-column
                    align="center"
                    width="130"
                    prop="electrifiedImmigrationGroupsCount"
                    label="数量（个）"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    width="150"
                    prop="electrifiedImmigrationGroupsRatio"
                    label="占移民组比率（%）"
                  >
                    <template slot-scope="scope">
                      {{
                        scope.row.electrifiedImmigrationGroupsRatio
                          ? scope.row.electrifiedImmigrationGroupsRatio + '%'
                          : ''
                      }}
                    </template>
                  </el-table-column>
                </el-table-column>

                <el-table-column align="center" label="能接收电视的移民组">
                  <el-table-column
                    align="center"
                    width="130"
                    prop="tvAccessibleImmigrationGroupsCount"
                    label="数量（个）"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    width="150"
                    prop="tvAccessibleImmigrationGroupsRatio"
                    label="占移民组比率（%）"
                  >
                    <template slot-scope="scope">
                      {{
                        scope.row.tvAccessibleImmigrationGroupsRatio
                          ? scope.row.tvAccessibleImmigrationGroupsRatio + '%'
                          : ''
                      }}
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table-column>

              <el-table-column align="center" fixed="right" label="操作" width="90">
                <template slot-scope="scope">
                  <div class="flex">
                    <el-link v-if="scope.$index >= 3" type="primary" @click="handleView('view', scope.row)"
                      >查看</el-link
                    >
                    &nbsp; &nbsp;
                    <el-link v-if="scope.$index >= 3" type="danger" @click="handleDelete(scope.$index, scope.row)"
                      >删除</el-link
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        </div>
      </div>
    </div>

    <formHandle
      v-else="componentName"
      ref="formHandle"
      @refreshTable="refreshTable"
      @handleBack="handleBack"
    ></formHandle>

    <tempalteImport ref="tempalteImport" @refreshTable="refreshTable" />
    <!-- 分页组件 -->
  </div>
</template>

<script>
import {
  _ProductionLivingPage,
  _ProductionLivingExport,
  _ProductionLivingDelete
} from '@/api/modular/postImmigrationService/monitorAndEvaluate/changeSituation.js'

import Pagination from '@/components/Pagination'
import templateTitle from '@/components/templateView/templateTitle.vue'
import formHandle from '@/views/postImmigrationService/monitorAndEvaluate/changeSituation/components/formHandle.vue'
import templateTree from '@/components/templateView/templateTree.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'
import tempalteImport from '@/components/templateView/tempalteImport.vue'

import { _getLevelRegion } from '@/api/keepHome'

export default {
  data() {
    return {
      type: '',
      showActions: false,
      selectedRows: [], // 当前选中的行
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: null
      },
      loading: false, //是显示加载
      searchObj: {
        year: ''
      },
      rules: {
        year: [{ required: true, message: '请选择预算年度', trigger: 'blur' }]
      },
      listData: [],
      treeData: [],
      breadList: [
        {
          id: Math.random(),
          value: '列表页'
        }
      ],
      componentName: '',
      downloadObj: {},
      dck: [],
      OrgId: null,
      IsLevel: false,
      OrgPids: null,
      years: []
    }
  },
  // 注册组件
  components: {
    Pagination,
    templateTitle,
    formHandle,
    tempalteImport,
    templateTree,
    budgetYear
  },
  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {
    this.init()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    init() {
      this.getLevelRegion()
      //this.getPage()
    },
    handleResize() {
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    },
    getLevelRegion(val) {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.treeData = res.data
          let provincialCenterId = this.treeData[0].id //默认展开第一个节点
          this.dck = [provincialCenterId]
          this.downloadObj = this.treeData[0]
        }
      })
    },

    refreshTable() {
      this.getPage()
    },
       refreshNode(obj, IsLevel) {
      this.OrgId = obj.id
      this.downloadObj = obj
      this.IsLevel = IsLevel
      this.OrgPids = obj.pids
      this.pageparm = {
        currentPage: 1,
        pageSize: 10,
        total: null
      }
      this.getPage()
    },
    exportExcel() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let params = {
            year: this.searchObj.year,
            OrgId: this.OrgId ? this.OrgId : null, //行政区域编号
            OrgName: this.downloadObj.name, //行政区域名称
            OrgPids: this.downloadObj.pids //行政区域编号path
          }

          console.log(params, '====params===')

          // return
          _ProductionLivingExport(params).then(res => {
            if (res.status == 200) {
              this.downloadfile(res)
            } else {
              this.$message.error('导出失败')
            }
          })
        } else {
          this.$message.error('请选择导出年度')
        }
      })
    },
    importExcel() {
      this.$refs.tempalteImport.view(this.searchObj.year, this.years, '1-7-2')
    },

    handleBack() {
      this.breadList.splice(1, 1)
      this.componentName = ''
    },
    // 获取page列表
    getPage() {
      const { currentPage, pageSize, total } = this.pageparm
      this.loading = true
      const params = {
        pageNo: currentPage,
        pageSize: pageSize,
        ...this.searchObj,
        OrgId: this.OrgId,
        OrgPids: this.OrgPids,
        IsLevel: this.IsLevel
      }

      _ProductionLivingPage(params)
        .then(res => {
          if (res.code == 200) {
            this.pageparm.total = res.data.totalRows

            let listData = res.data.rows

            this.listData = listData
              .map(ele => {
                return [
                  {
                    ...ele.productionLivingBefore,
                    year: ele.productionLiving?.year - 1 + '年底',
                    viewId: ele.productionLiving.id
                  },
                  {
                    ...ele.productionLiving,
                    year: ele.productionLiving?.year + '年底',
                    viewId: ele.productionLiving.id
                  },
                  {
                    ...ele.productionLivingIncrease,
                    year: `${ele.productionLiving?.year}比${ele.productionLiving?.year - 1}年增加`,
                    viewId: ele.productionLiving.id
                  }
                ]
              })
              .flat(2)

            if (res.data.totalRow) {
              let ele1 = res.data.totalRow
              this.listData.unshift(
                {
                  ...ele1.productionLivingBefore,
                  year: ele1.productionLiving?.year - 1 + '年底'
                },
                { ...ele1.productionLiving, year: ele1.productionLiving?.year + '年底' },
                {
                  ...ele1.productionLivingIncrease,
                  year: `${ele1.productionLiving?.year}比${ele1.productionLiving?.year - 1}年增加`
                }
              )
            }

            console.log(this.listData, '========= this.listData==========')

           

            this.loading = false
          } else {
            this.loading = false
            this.$message.error('获取失败：' + res.message)
          }
        })
        .finally(res => {
          this.loading = false
        })
    },
    handleDelete(index, row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const params = [{ id: row.viewId }]
          _ProductionLivingDelete(params).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
            this.getPage()
            } else {
              this.$message({
                type: 'error',
                message: res.message
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 搜索事件
    search() {
      this.pageparm.currentPage = 1
      this.getPage()
    },
    refreshYear(year, years) {
      this.searchObj.year = year
      this.years = years
      this.pageparm.currentPage = 1
      this.getPage()
    },
    reset() {
      let nowYear = new Date().getFullYear()
      if (nowYear <= 2023) {
        this.searchObj = {
          year: nowYear - 1
        }
      } else {
        this.searchObj = {
          year: nowYear - 2
        }
      }

      this.OrgId = null
      this.IsLevel = false
      this.OrgPids = null
      this.pageparm = {
        currentPage: 1,
        pageSize: 10,
        total: null
      }

      this.$refs.budgetYear.reset(this.searchObj.year)
      this.$refs.templateTree.resetLevel()

      this.getPage()
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 32) {
        const _row = this.flitterData(this.listData).one[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    flitterData(arr) {
      // 合并表格第一列
      let spanOneArr = []
      let concatOne = 0
      arr.forEach((item, index) => {
        if (index >= 1) {
          // 这里的type_test是表格绑定的字段，也就是需要合并的字段，根据自己的需求来改
          if (item.county == arr[index - 1].county) {
            // 第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1
            spanOneArr.push(0)
          } else {
            spanOneArr.push(1)
            concatOne = index
          }
        } else {
          spanOneArr.push(1)
        }
      })
      return {
        one: spanOneArr
      }
    },
    handleAdd(type, row) {
      let obj = {
        id: Math.random(),
        value: '新增'
      }
      this.breadList.splice(this.breadList.length, 0, obj)
      this.componentName = type
      this.type = type
      this.$nextTick(() => {
        this.$refs.formHandle.add(type)
      })
    },

    handleView(type, row) {
      let obj = {
        id: Math.random(),
        value: type == 'view' ? '详情' : '查看统计图'
      }
      this.breadList.splice(this.breadList.length, 0, obj)
      this.componentName = type
      this.type = type
      this.$nextTick(() => {
        this.$refs.formHandle.view(type, row.viewId)
      })
    },

    refreshTable() {
      this.getPage()
    },

    // 选择select
    handleSelectionChange(selection) {
      this.selectedRows = selection
      this.showActions = selection.length > 0
    },
    isSelect({ row }) {
      const checkIdList = this.selectedRows.map(item => item.data1)
      if (checkIdList.includes(row.data1)) {
        return {
          color: '#e17009'
        }
      }
    },

    // 分页插件事件
    callFather(parm) {
      this.pageparm.currentPage = parm.currentPage
      this.pageparm.pageSize = parm.pageSize
      this.getPage()
    },
    downloadfile(res) {
      var contentDisposition = res.headers['content-disposition']
      if (contentDisposition) {
        const regex = /filename*.+?\.xlsx/g
        const matches = contentDisposition.match(regex)
        const filename1 = matches[1].replace(/['"]/g, '')
        const startIndex = filename1.indexOf('filename*=UTF-8') + 'filename*=UTF-8'.length
        const result = filename1.substring(startIndex)
        const filename = result.replace(/^_+/g, '')
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(res.data) // 创建下载的链接
        var reg = /^["](.*)["]$/g
        downloadElement.style.display = 'none'
        downloadElement.href = href
        downloadElement.download = decodeURI(filename.replace(reg, '$1')) // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href)
      } else {
        this.$message.error('导出失败')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/elementui.scss';
::v-deep .el-form-item {
  margin-bottom: 0px;
}

::v-deep tbody tr.el-table__row:nth-child(1) {
  height: 49px;
  border: 1px solid #f53f3f;
  background-color: #fff0f0;
  position: sticky !important;
  top: 0;
  left: 0;
  z-index: 1;
  td {
    border-top: 1px solid red !important;
    // border-left: 1px solid red !important;
    border-bottom: 1px solid red !important;
  }
  td:nth-child(2) {
    border-left: 1px solid red !important;
  }
  td:nth-child(32) {
    border-right: 1px solid red !important;
  }
  td:last-child {
    border-right: 1px solid red !important;
  }
}
::v-deep tbody tr.el-table__row:nth-child(2) {
  height: 49px;
  border: 0 !important;
  border: 1px solid #f53f3f;
  background-color: #fff0f0;
  position: sticky !important;
  top: 49px;
  left: 0;
  z-index: 1;
  td {
    // border-top: 1px solid red !important;
    border-bottom: 1px solid red !important;
  }
  td:first-child {
    border-left: 1px solid red !important;
  }
  td:last-child {
    border-right: 1px solid red !important;
  }
}
::v-deep tbody tr.el-table__row:nth-child(3) {
  height: 49px;
  border: 0 !important;
  border: 1px solid #f53f3f;
  background-color: #fff0f0;
  position: sticky !important;
  top: 98px;
  left: 0;
  z-index: 1;
  td {
    // border-top: 1px solid red !important;
    border-bottom: 1px solid red !important;
  }
  td:first-child {
    border-left: 1px solid red !important;
  }
  td:last-child {
    border-right: 1px solid red !important;
  }
}
</style>
